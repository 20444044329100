<script>
import axios from 'axios';
import Vue from 'vue';

export default {
  name: 'MarketingPlanEdit',
  components: { },
  data: () => ({
    editing: false,
    finishedOrCanceled: false,
    selectedStatus: [],
    client: {
      error: false,
      loading: true,
      loaded: false,
      id: null,
      data: {},
    },
    marketing_plan: {
      error: false,
      loading: false,
      saving: false,
      data: {
        uuid: null,
        client_id: null,
        key: null,
        status: null,
        journey_id: null,
        description: null,
        template: null,
        start_date: null,
        type: null,
      },
    },
    touchpoints: {
      error: false,
      loading: false,
      saving: false,
      fields: [
        { key: 'channel', label: 'Channel' },
        { key: 'date', label: 'Date (YYYY-MM-DD)' },
        { key: 'product', label: 'Product' },
        { key: 'incentive', label: 'Incentive' },
        { key: 'status', label: 'Status' },
        { key: 'key', label: 'Key' },
        { key: 'audience', label: 'Audience' },
      ],
      data: [],
      file: null,
      statuses: {
        created: {
          name: 'Created',
          selected: false,
        },
        canceled: {
          name: 'Canceled',
          selected: false,
        },
        executed: {
          name: 'Executed',
          selected: false,
        },
      },
    },
    touchpoint_data: {
      saving: false,
      error: false,
      minDate: null,
      maxDate: null,
      data: {
        uuid: null,
        channel: null,
        date: null,
        product: null,
        status: null,
        incentive_description: null,
        incentive_value: null,
        incentive_currency: null,
      },
    },
    currentPage: 1,
    total: 0,
    page_size: 10,
    options: {
      marketing_plan: {
        minDate: null, // new Date(),
        maxDate: null, // new Date().setFullYear(new Date().getFullYear() + 1),
        description: [
          { text: 'Enrollment', value: 'enrollment' },
          { text: 'Treatment completion', value: 'treatment_completion' },
        ],
        status: [
          { text: 'Active', value: 'active' },
          { text: 'Finished', value: 'finished' },
          { text: 'Canceled', value: 'canceled' },
        ],
        type: [
          { text: 'Custom', value: 'custom' },
          { text: 'Standard', value: 'standard' },
        ],
      },
      touchpoint: {
        channel: [
          { text: 'Email', value: 'email' },
          { text: 'Mailer', value: 'mailer' },
          { text: 'SMS', value: 'sms' },
          { text: 'Phone Call', value: 'phone_call' },
        ],
        status: [
          { text: 'Created', value: 'created' },
          { text: 'Canceled', value: 'canceled' },
          { text: 'Executed', value: 'executed' },
        ],
        incentive_currency: [
          { text: 'USD', value: 'usd' },
        ],
        product: [
          { text: 'Platform', value: 'platform' },
          { text: 'DPT', value: 'dpt' },
          { text: 'Bloom', value: 'bloom' },
          { text: 'Move', value: 'move' },
        ],
      },
    },
  }),
  beforeMount() {
    this.client.id = this.$route.params.clientID;
    this.fetchClient();
    if (this.$route.params.marketingPlanUUID) {
      this.editing = true;
      this.marketing_plan.data.uuid = this.$route.params.marketingPlanUUID;
      this.fetchMarketingPlan();
      this.fetchMarketingTouchPoints();
    }
  },
  computed: {
    overlay() {
      if (this.client.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching client',
        };
      }
      if (this.client.error) {
        return {
          show: true,
          icon: 'bug',
          animation: 'spin',
          text: 'Error fetching client',
        };
      }
      if (this.marketing_plan.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching marketing plan',
        };
      }
      if (this.marketing_plan.saving) {
        return {
          show: true,
          icon: 'save',
          animation: 'throb',
          text: 'Saving marketing plan',
        };
      }
      if (this.marketing_plan.error) {
        return {
          show: true,
          icon: 'bug',
          animation: 'spin',
          text: 'Error fetching marketing plan',
        };
      }

      if (this.touchpoints.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching touch points',
        };
      }
      if (this.touchpoints.error) {
        return {
          show: true,
          icon: 'bug',
          animation: 'spin',
          text: 'Error fetching touchpoints',
        };
      }

      return {
        show: false,
        icon: '',
        animation: '',
        text: '',
      };
    },
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.fetchMarketingTouchPoints();
    },
    fetchClient() {
      this.client.loading = true;
      return axios.get(`v1/clients/${this.client.id}`, { params: { by: 'id' } })
        .then(response => {
          this.client.data = response.data.client;
          this.client.loaded = true;
        })
        .catch(e => {
          this.$noty.error('Error loading client');
          this.client.error = true;
          console.error(e);
        })
        .finally(() => {
          this.client.loading = false;
        });
    },
    fetchMarketingPlan() {
      this.marketing_plan.loading = true;
      return axios.get(`v1/marketing/marketing-plan/${this.marketing_plan.data.uuid}`)
        .then(response => {
          this.marketing_plan.data = response.data;
          this.finishedOrCanceled = this.marketing_plan.data.status === 'finished' || this.marketing_plan.data.status === 'canceled';
          // this.options.touchpoint.minDate = new Date(this.marketing_plan.data.start_date);
          // this.options.touchpoint.maxDate = new Date(this.marketing_plan.data.start_date);
          // this.options.touchpoint.maxDate.setFullYear(this.options.touchpoint.maxDate.getFullYear() + 1);
        }).catch(e => {
          Vue.prototype.$noty.error('Cannot fetch marketing plan', e);
        }).finally(() => {
          this.marketing_plan.loading = false;
          this.marketing_plan.loaded = true;
        });
    },
    fetchMarketingTouchPoints() {
      this.touchpoints.loading = true;
      return axios.get('v1/marketing/touchpoint', {
        params: {
          page_number: this.currentPage,
          page_size: this.page_size,
          marketing_plan_uuid: this.marketing_plan.data.uuid,
          statuses: this.selectedStatus && JSON.stringify(this.selectedStatus),
        },
      })
        .then(response => {
          this.touchpoints.data = response.data.touchpoints;
          this.total = response.data.total;
        }).catch(e => {
          Vue.prototype.$noty.error('Cannot fetch touchpoints', e);
        }).finally(() => {
          this.touchpoints.loading = false;
          this.touchpoints.loaded = true;
        });
    },
    openConfirmationModal() {
      let text = 'Are you sure you want to save?';
      if (this.marketing_plan.data.status === 'canceled') {
        text = 'Are you sure? This will cancell all the touchpoints of this plan';
      }
      return this.$bvModal.msgBoxConfirm(text, { title: 'Confirmation' })
        .then(value => {
          if (!value) {
            return;
          }
          this.marketing_plan.saving = true;
          const data = {
            client_id: this.client.id,
            key: this.marketing_plan.data.key,
            status: this.marketing_plan.data.status,
            journey_id: this.marketing_plan.data.journey_id,
            description: this.marketing_plan.data.description,
            template: this.marketing_plan.data.template,
            start_date: this.marketing_plan.data.start_date,
            type: this.marketing_plan.data.type,
          };
          let method = 'post';
          let url = 'v1/marketing/marketing-plan';
          if (this.editing) {
            data.uuid = this.marketing_plan.data.uuid;
            url = `v1/marketing/marketing-plan/${data.uuid}`;
            method = 'patch';
          }

          axios.request({ method, url, data })
            .then(response => {
              this.$noty.success('Marketing plan saved');
              this.$router.push(`/client/${this.client.id}/marketing-plan/${response.data.uuid}`);
              // force redirect
              this.$router.go();
            })
            .catch(e => {
              this.$noty.error('Error saving marketing plan');
              console.error(e);
            })
            .finally(() => {
              this.marketing_plan.saving = false;
            });
        })
        .catch(() => {
          this.$noty.error('Cannot open confirmation dialog');
        });
    },
    saveTouchpoint() {
      this.touchpoint_data.saving = true;
      const data = {
        marketing_plan_uuid: this.marketing_plan.data.uuid,
        channel: this.touchpoint_data.data.channel,
        date: this.touchpoint_data.data.date,
        product: this.touchpoint_data.data.product,
        status: this.touchpoint_data.data.status,
        incentive_description: this.touchpoint_data.data.incentive_description,
        incentive_value: this.touchpoint_data.data.incentive_value,
        incentive_currency: this.touchpoint_data.data.incentive_currency,
      };
      let method = 'post';
      let url = '/v1/marketing/touchpoint';
      if (this.touchpoint_data.data.uuid) {
        data.uuid = this.touchpoint_data.data.uuid;
        url = `/v1/marketing/touchpoint/${data.uuid}`;
        method = 'patch';
      }

      axios.request({ method, url, data })
        .then(() => {
          this.$noty.success('Touchpoint saved');
          this.fetchMarketingTouchPoints();
          this.$bvModal.hide('modal-edit-touchpoint');
        })
        .catch(e => {
          this.$noty.error('Error saving touchpoint');
          console.error(e);
        })
        .finally(() => {
          this.touchpoint_data.saving = false;
        });
    },
    async uploadTouchpointtFile() {
      if (!this.touchpoints.file) {
        return;
      }

      if (this.touchpoints.file.name.split('.').pop() !== 'csv') {
        this.$noty.error('Please select a CSV file type');
        return;
      }

      const proceed = await this.$bvModal.msgBoxConfirm(
        'By uploading this file you will be replacing the actual touchpoints. Are you sure you want to proceed?',
        {
          title: 'Marketing Touchpoints configuration',
          okTitle: 'Proceed',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        },
      );

      if (!proceed) {
        return;
      }

      try {
        this.touchpoints.loading = true;

        const formData = new FormData();
        formData.append('file', this.touchpoints.file);

        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };

        await axios.post(`v1/marketing/${this.marketing_plan.data.uuid}/touchpoint/upload`, formData, config);
        this.$noty.success('Touchpoint file uploaded with success');
      } catch (error) {
        if (error?.response?.data?.message?.includes("'Key' values cannot be empty.")) {
          this.$noty.error("Unable to upload touchpoint file: 'Key' values cannot be empty.\n");
        } else {
          this.$noty.error(this.formatTouchPointError('Unable to upload touchpoint file\n', error));
        }
      } finally {
        await this.fetchMarketingTouchPoints();
        this.touchpoints.loading = false;
      }
    },
    async downloadTouchpointFile() {
      try {
        const { data } = await axios.get(`/v1/marketing/touchpoint/download/${this.marketing_plan.data.uuid}`);

        const fileURL = window.URL.createObjectURL(new Blob([ data ]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', this.touchpoints.fileName || `${this.client.id}_marketing_touchpoints.csv`);
        document.body.appendChild(fileLink);

        fileLink.click();
        URL.revokeObjectURL(fileLink.href);
      } catch (error) {
        this.$noty.error(this.formatTouchPointError('Unable to download marketing touchpoint file:\n', error));
      }
    },
    formatTouchPointError(defaultMessage, err) {
      let errorDetail = '';
      if (err.response && err.response.data && err.response.data.error?.detail) {
        errorDetail = err.response.data.error.detail;
      }
      return defaultMessage + errorDetail;
    },
    color(statusKey) {
      if (!this.touchpoints.statuses[statusKey].selected) {
        return `outline-${statusKey}`;
      }
      return statusKey;
    },
    selectStatusFilter(statusKey) {
      if (!this.touchpoints.statuses[statusKey].selected) {
        this.selectedStatus.push(statusKey);
      } else {
        this.selectedStatus = this.selectedStatus.filter(val => val !== statusKey);
      }
      this.touchpoints.statuses[statusKey].selected = !this.touchpoints.statuses[statusKey].selected;
      this.fetchMarketingTouchPoints();
    },
  },
};
</script>

<template>
  <b-container class="mt-4" fluid>
    <b-row>
      <b-col cols="12" class="mb-2">
        <b-button class="float-left mr-2" variant="light"
        :to="`/onboarding/client/edit/${client.id}`">
          <feather type="arrow-left"></feather>
        </b-button>
        <h3 v-if="editing">Editing marketing plan for {{ client.data.display_name }}</h3>
        <h3 v-else>New marketing plan for {{ client.data.display_name }}</h3>
      </b-col>
    </b-row>
    <b-overlay :show="overlay.show">
      <template #overlay>
        <div class="text-center">
          <b-icon :animation="overlay.animation" :icon="overlay.icon" font-scale="3"></b-icon>
          <p id="cancel-label">{{ overlay.text }}</p>
        </div>
      </template>
      <b-card>
        <b-card-text>
          <b-row>
            <b-col cols="12">
              <h5>Plan data</h5>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Key:" label-for="marketing_plan.data.key">
                <b-form-input id="marketing_plan.data.key" v-model.trim="marketing_plan.data.key" :disabled="editing"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Journey ID:" label-for="marketing_plan.data.journey_id">
                <b-form-input id="marketing_plan.data.journey_id" v-model.trim="marketing_plan.data.journey_id" type="number" min="1"
                  :disabled="finishedOrCanceled"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Description" v-slot="{ ariaDescribedby }">
                <b-form-select v-model="marketing_plan.data.description" :options="options.marketing_plan.description"
                               :aria-describedby="ariaDescribedby" required :disabled="finishedOrCanceled"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Start date" v-slot="{ ariaDescribedby }">
                <b-form-datepicker label="Start date" v-model="marketing_plan.data.start_date" :min="options.marketing_plan.minDate"
                                   :max="options.touchpoint.maxDate" required :disabled="editing"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Type" v-slot="{ ariaDescribedby }">
                <b-form-select v-model="marketing_plan.data.type" :options="options.marketing_plan.type"
                               :aria-describedby="ariaDescribedby" required :disabled="editing"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Status" v-slot="{ ariaDescribedby }">
                <b-form-select v-model="marketing_plan.data.status" :options="options.marketing_plan.status"
                               :aria-describedby="ariaDescribedby" required :disabled="finishedOrCanceled"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="!finishedOrCanceled">
            <b-col cols="12">
              <b-button variant="primary" @click="openConfirmationModal" class="float-right">Save marketing plan
              </b-button>
            </b-col>
          </b-row>

          <template v-if="editing">
            <hr class="mb-3"/>
            <b-row>
              <b-col cols="8">
                <h5>Touchpoints</h5>
              </b-col>
            </b-row>
            <b-row cols="8">
              <b-alert class="ml-2" show variant="warning">Uploading a CSV replaces all current touchpoints.</b-alert>
            </b-row>
            <b-row cols="8">
              <b-col cols="4">
                  <b-form-file id="input-touchpoint-file" v-model="touchpoints.file"
                    placeholder="Upload CSV"
                    :disabled="!!finishedOrCanceled"
                    required drop-placeholder="Drop file here..." accept=".csv" />
              </b-col>
              <b-col cols="2">
                  <b-button :disabled="!!finishedOrCanceled" variant="warning"
                    @click="uploadTouchpointtFile">
                    Upload CSV
                  </b-button>
              </b-col>
            </b-row>
            <b-row cols="12" class="mt-3">
              <b-col cols="2">
                <b-button variant="btn btn-success" @click.prevent="downloadTouchpointFile">
                  Download CSV
                </b-button>
              </b-col>
              <b-col cols="10">
                  <b-col cols="2" v-for="(status, statusKey) in touchpoints.statuses" :key="statusKey" v-model="selectedStatus"  class="float-right">
                    <b-button
                      @click="selectStatusFilter(statusKey)"
                      :variant="color(statusKey)">
                        {{ status.name }}
                    </b-button>
                  </b-col>


              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="12">
                <b-table :fields="touchpoints.fields" :items="touchpoints.data"
                         outlined striped small :show-empty="true" empty-text="No touchpoints">
                  <template #cell(incentive)="data">
                    <ul class="list-unstyled">
                      <li><b>Description</b>: {{ data.item.incentive_description }}</li>
                      <li><b>Value</b>: {{ data.item.incentive_value }}</li>
                      <li><b>Currency</b>: {{ data.item.incentive_currency }}</li>
                    </ul>
                  </template>

                  <template #cell(status)="data">
                    <b-badge v-if="data.item.status === 'created'" variant="secondary">{{data.item.status}}</b-badge>
                    <b-badge v-if="data.item.status === 'canceled'" variant="warning">{{data.item.status}}</b-badge>
                    <b-badge v-if="data.item.status === 'executed'" variant="success">{{data.item.status}}</b-badge>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <div class="row pt-2">
            <div class="col-12 d-flex justify-content-center">
              <b-pagination :disabled="touchpoints.loading"
                            @change="changePage"
                            v-model="currentPage"
                            :total-rows="total"
                            :per-page="page_size"
                            size="md">
              </b-pagination>
            </div>
          </div>
          </template>
        </b-card-text>
      </b-card>
    </b-overlay>

  </b-container>
</template>

<style lang="scss">
  $executed: #53a653;
  $canceled: #f0ad4e;
  $created : #6C757D;

  .btn-executed {
  color: #fff;
  background-color: $executed;
  &:hover {
    color: #fff;
  }
}

.btn-outline-executed {
  border-color: $executed;
  color: $executed;

  &:hover {
    color: #fff;
    background-color: $executed;
  }

  .btn-executed {
  color: #fff;
  background-color: $executed;
  &:hover {
    color: #fff;
  }
}
}

.btn-canceled {
  color: #fff;
  background-color: $canceled;
  &:hover {
    color: #fff;
  }
}

.btn-outline-canceled {
  border-color: $canceled;
  color: $canceled;

  &:hover {
    color: #fff;
    background-color: $canceled;
  }
}

.btn-created {
  color: #fff;
  background-color: $created;
  &:hover {
    color: #fff;
  }
}

.btn-outline-created {
  border-color: $created;
  color: $created;

  &:hover {
    color: #fff;
    background-color: $created;
  }
}

</style>
